@tailwind base;
@tailwind components;
@tailwind utilities;

/* Theme Colors */
:root {
  --accent-1: #000074;
  --accent-2: #123CB8;
  --contrast-1: #EF2E33;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-cover-logo {
  background-image: url('../src/resources/images/CoverLogoImage.jpg');
  background-size: contain;
}

.bg-waives {
  position: relative;
  overflow: hidden;
  background-image: url('../src/resources/images/CoverImage.png');
  background-size: cover;
}

.header {
  position: absolute;
  color: white;
  z-index: 10;
  overflow: hidden;
  width: 100%;
}

.inner-header {
  content: "";
  height: 100vh;
  width: 100%;
  margin: 0;
}

.flex-css {
  /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
}

.waves {
  position: relative;
  height: 20vh;
  /*Fix for safari gap*/
  min-height: 20vh;
  transform: rotate(-90deg);
}

.content {
  position: relative;
  height: 20vh;
  text-align: center;
  background-color: white;
}

/* Animation */

.parallax>use {
  animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax>use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 14s;
}

.parallax>use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 20s;
}

.parallax>use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 26s;
}

.parallax>use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 40s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }

  .content {
    height: 30vh;
  }

  h1 {
    font-size: 24px;
  }
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.dropdown:focus-within .dropdown-menu {
  opacity: 1;
  transform: translate(0) scale(1);
  visibility: visible;
}

hr + hr {
  display: none;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.ReactModal__Overlay {
  z-index: 20;
}

.hasImage:hover section {
  background-color: rgba(5, 5, 5, 0.4);
}

.hasImage:hover button:hover {
  background: rgba(5, 5, 5, 0.45);
}

#overlay p,
i {
  opacity: 0;
}

#overlay.draggedover {
  background-color: rgba(255, 255, 255, 0.7);
}

#overlay.draggedover p,
#overlay.draggedover i {
  opacity: 1;
}

.group:hover .group-hover\:text-blue-800 {
  color: #2b6cb0;
}

.Modal {
  position: absolute;
  inset: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: hidden;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.ReactModal__Content {
  top: 10%;
  left: 5px;
  right: 5px;
  bottom: auto;
  opacity: 0;
}

.ReactModal__Content--after-open {
  transform: translateY(-100%);
  animation: modalOpen 0.5s forwards;
}

.ReactModal__Content--before-close {
  transform: translateY(0);
  animation: modalClose 0.8s forwards;
}

/* Modal for non mobile screens */
@media (min-width: 640px) {
  .ReactModal__Content {
    top: 20%;
    left: 30%;
    right: 30%;
    bottom: auto;
    opacity: 0;
  }
}

@media (min-width: 768px) {
  #tblCondiciones thead tr:not(:first-child) {
    display: none;
  }
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.ReactCollapse--content {}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(0 0 116) white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
  /* // width of the entire scrollbar  */
}

*::-webkit-scrollbar-track {
  background: white;
  /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(239 46 51);
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid white;
  /* creates padding around scroll thumb */
}

#divFilterMenuBody::before {
  content: "";
  position: absolute;
  top: -11px;
  left: 4px;
  z-index: 1;
  border: solid 10px transparent;
  border-bottom-color: #FFF;
}

/* Removing table wrapper for menu overflow */
.filterMenuActive {
  position: relative;
  width: 100%;
  border-radius: 20px;
  min-height: 0;
}

.line {
  width: 100%;
  height: 2rem;
  overflow: hidden;
  padding: 0;
}

/* lineup class and keyframes */
.lineUp {
  animation: 2s anim-lineUp ease-out normal;
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

/*
 * SVG Progress Indicator
 */
.svg-pi-wrapper {
  position: relative;
}

.svg-pi {
  transform: rotate(-90deg);
  /* Fix the orientation */
}

/* Animated spinner version */
.svg-pi-indicator--spinner {
  animation: spinner .75s linear infinite;
  transform-origin: center;
}

.svg-pi-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.svg-pi-label__loading {
  opacity: .5;
  font-size: 0.75em;
}

.svg-pi-label__progress {
  font-size: 1.3em;
  font-weight: 600;
}

.svg-pi-label__loading,
.svg-pi-label__progress {
  display: block;
}

/* Spinner animation */
@keyframes spinner {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}

/* Tooltip */
div.tooltip-default {
  font-size: small;
}

@keyframes modalOpen {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes modalClose {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}